











































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarFinanceReports from "@/components/sidebar-finance-reports.vue";
import ReportService from "@/services/reports-service";
import accountService from '@/services/account-service';
@Component({
  components: {
    Header,
    SidebarFinanceReports,
  },
})
export default class StatementsOfAccounts extends Vue {
  private data: any[] = [];
  public filter = null;
  public filterOn = [];
  trans = [];
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  selected_report_date_from: any = '';
  selected_report_date_to: any = '';
  public options_account = []
  public account_selected: any = {}
  side_tran_entry: any = [];
  side_tran: any = {};
  get rows() {
    return this.selected_items.length;
  }
  public items = [];

  public fields = [
    {
      key: "date",
    },
    {
      key: "transaction",
      label: "Transaction",
    },
    {
      key: "description",
    },
    {
      key: "account_title",
    },
    {
      key: "account_code",
    },
    {
      key: "cost_center",
    },
    {
      key: "debit",
    },
    {
      key: "credit",
    },
    {
      key: "balance",
    },
    {
      key: "b_type",
      label: "Type"
    },
    {
      key: "po_no",
    },
    {
      key: "request_no",
    },
    {
      key: "description",
    },

  ];
  public selected_items = [
    {
      account_title: "",
      debit: 0,
      credit: 0,
      balance: "",
      date: "",
    },
  ];

  retrieve(id: any) {
    this.selected_items = [];
    ReportService.getledger(id)
      .then((response) => {
        if (response.data.result.length > 0) {
          this.selected_items = response.data.result;
        } else {
          this.selected_items.push(
            {
              account_title: "No Record Found",
              date: "",
              debit: 0,
              credit: 0,
              balance: ""
            },
          )
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mounted() {
    // accountService.getAccounts()
    // .then((response) => {  
    //   this.options_account = response.data.result;      
    //   })
    // .catch((e) => {
    //   console.log(e);
    // });
  }

  submit() {
    let obj = {
      start_date: this.selected_report_date_from,
      end_date: this.selected_report_date_to,
      project: this.account_selected.id,
    }
    this.retrieve(obj);
  }

  onRowClicked(item: any, index: any, event: any) {
    ReportService.get_transaction_id(item.transaction_id)
      .then((response) => {
        this.side_tran = response.data.transaction[0]
        this.side_tran_entry = response.data.entry
      })
      .catch((e) => {
        console.log(e);
      });
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant");
  }



  get_parent_account(data: any) {
    this.account_selected = data
    this.options_account = [];
  }

  search_parent_account(title: any) {
    if (title.length > 4) {
      accountService.getByTitleMaster(title)
        .then((response) => {
          this.options_account = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.options_account = [];
    }
  }
}
